import React from 'react'
import propTypes from "prop-types";
import _ from 'lodash';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import * as XLSX from 'xlsx';
import { Table, Button, Empty, message, Input, Space, Tooltip, Select, DatePicker, Modal } from 'antd'
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons"
import Highlighter from 'react-highlight-words';
import errorIcon from "../../assets/message_error.png"
import { GET } from "../../frameworks/HttpClient";
import { objectMap, removeEmpty, replaceKey } from '../../frameworks/Util';
import "../../assets/fonts/THSarabunNew-normal"
import "../../assets/fonts/THSarabunNew-bold"
import "../../assets/fonts/THSarabunNew-italic"
import "../../assets/fonts/THSarabunNew-bolditalic"
import { useTranslation } from 'react-i18next';

const DATE_FORMAT = 'YYYY-MM-DD'
const HUMAN_DATE_FORMAT = 'DD/MM/YYYY'
const MINIMUM_CONFIRM_EXPORT_ROWS = 100;

/**
 * Table component which support server side rendering with Django
 * Sample columns
 * columns = [
      {
        title: 'Member ID',
        dataIndex: 'code',
        key: 'code',
        sorter: true,
        searcher: true,
      },
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        searcher: true,
        searcherOptions: [
          { key: 'all', value: null, label: 'all' },
          { key: 'active', value: 'active', label: 'Active' },
          { key: 'inactive', value: 'inactive', label: 'InActive' },
        ],
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        dateSearcher: true,  // Search by date selection
      },
      {
        title: "Icon",
        dataIndex: "status", 
        render : (value) => {
          return (
            <div style={{ textAlign : 'center', fontSize : 20 }}>
              {RenderIconStatus(value)}
            </div>)
        },
        renderReport: (value) => {  // New function for render on report
          return value ? "Yes" : "No"
        }
      },
      {
        title: "Action",
        dataIndex: '',
        skipReport: true,  // set skip to report to ignore this column from report
        render: (value) => {......}
      }
    ]

  ** Export PDF
    tableRef.exportPDF({
      title="Sample Report" , 
      subtitle="report description",  // optional (default = null)
      landscape=false, // page orientation (default false = portrait)
      fetchPageSize=50  // page size to fetch from server (default 50)
    })
      
  ** Export Xlsx
   tableRef.exportXlsx({
    title = "Sample Report", 
    subtitle = "report description", // optional (default = null)
    filename=null, // Name of the file to download (file name must include .xlsx) if null download.xlsx will generated
    fetchPageSize = 50 // page size to fetch from server (default 50)
   })
 */

const TSTable = React.forwardRef((props, ref) => {
  const FLAG_COLUMN_SEARCH = "searcher"
  const [pagination, setPagination] = React.useState({ ...props.paginationOptions, total: 0, current: 1, pageSize: props.pageSize })
  const searchInput = React.useRef(null);
  const [searchItems, setSearchItems] = React.useState([]);
  const [filterParams, setFilterParams] = React.useState([]);
  const [sortParams, setSortParams] = React.useState(null);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const [errorMsg, setErrMsg] = React.useState(null);
  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    fetch: (additional_params) => {
      fetchData(props.url, 1, props.pageSize, null, null, additional_params)
    },
    fetchWithoutResetPage: () => {
      fetchData(props.url, pagination.current, props.pageSize)
    },

    fetchSearchWithoutResetPage: (additional_params) => {
      fetchData(props.url, pagination.current, props.pageSize, null, null, additional_params)
    },

    getCurrentPage: () => pagination.current,
    getTotalRows: () => pagination.total,
    exportPDF: (options) => validateExportReport(exportPDF, options),
    exportXlsx: (options) => validateExportReport(exportXlsx, options),
  }));

  const fetchData = async (url, page, pageSize, sort, filter, additional_params = {}, internalSearch = false) => {
    if (!url) {
      return;
    }
    setErrMsg(null)

    try {
      setIsLoading(true);
      const params = {
        ...props.params,
        ...additional_params,
        page: page,
        page_size: pageSize,
        ...(sort != null && { 'ordering': sort }),
        ...(filter != null && replaceKey(removeEmpty(objectMap(filter, value => value != null ? value[0] : value))))
      }
      const response = await GET(url, params)
      if (!internalSearch) {
        setPagination({
          ...props.paginationOptions,
          total: response.data['total'],
          current: response.data['current_page'],
          pageSize: pageSize,
        })
        setData(response.data['results'].map(row => ({ ...row, 'key': row[props.rowKey] })))
      }
      else {
        return {
          'current': response.data['current_page'],
          'pages': response.data['pages'],
          'data': response.data['results'].map(row => ({ ...row, 'key': row[props.rowKey] }))
        }
      }
    } catch (error) {
      setErrMsg(error['errorMessages'])
      messageApi.open({
        type: 'error',
        content: error['errorMessages'],
      });
    } finally {
      setIsLoading(false)
    }
  }

  const renderEmptyTable = () => {
    const errorContent = (
      <Empty
        image={errorIcon}
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            {errorMsg}
          </span>
        }>
        <Button type="default" icon={<ReloadOutlined />} onClick={() => fetchData(props.url, 1, props.PageSize)}>
          Reload
        </Button>
      </Empty>
    )

    const emptyContent = (<Empty></Empty>)

    return errorMsg ? errorContent : emptyContent
  }

  const handleSearch = (selectedKeys, confirm, dataIndex, title) => {
    confirm();
    setSearchItems([
      ...searchItems.filter(item => item.index !== dataIndex),
      {
        'index': dataIndex,
        'title': title,
        'value': selectedKeys[0]
      }
    ])
  }

  const handleReset = (clearFilters, confirm, dataIndex) => {
    setSearchItems(searchItems.filter(item => item.index !== dataIndex))
    clearFilters();
    confirm();
  }

  const getColumnSearchProps = (dataIndex, title, searchOptions, dateSearcher, renderFn) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {!searchOptions && !dateSearcher &&
          <Input
            ref={searchInput}
            placeholder={`Search ${title}...`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, title)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
        }

        {searchOptions &&
          <Select
            placeholder={`Search ${title}...`}
            value={selectedKeys[0]}
            options={searchOptions}
            onChange={value => setSelectedKeys([value])}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
        }

        {dateSearcher &&
          <DatePicker
            format={HUMAN_DATE_FORMAT}
            placeholder={`Search ${title}...`}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
            onChange={(value) => {
              setSelectedKeys([value.format(DATE_FORMAT)])
            }} />
        }

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex, title)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <Tooltip title="Click to show filter">
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : '#ffffff',
            fontSize: '150%'
          }}
        />
      </Tooltip>
    ),
    onFilterDropdownOpenChange: (visible) => visible ? setTimeout(() => searchInput.current?.select(), 100) : null,
    render: (value, original) => {
      if ((searchOptions || dateSearcher) && renderFn) {
        return renderFn(value, original)
      }
      else {
        const searchItem = searchItems.find(item => item.index === dataIndex)
        return searchItem ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchItem.value]}
            autoEscape
            textToHighlight={value ? value.toString() : ''}
          />
        ) : (value)
      }
    }
  })

  const fetchReportData = async (current, fetchSize, remoteData) => {
    const newData = await fetchData(
      props.url,
      current,
      fetchSize,
      sortParams,
      filterParams,
      {},
      true
    )
    if (newData.current === newData.pages) {  // last page reached
      return [...remoteData, ...newData.data]
    }
    else {  // recursive on next page
      return [...remoteData, ...await fetchReportData(current + 1, fetchSize, newData.data)]
    }
  }

  const validateExportReport = (exportFunc, options) => {
    if (isLoading) {
      return;  
    }

    if (pagination.total > MINIMUM_CONFIRM_EXPORT_ROWS) {
      modalApi.confirm({
        title: t("warning_modal.large_data"),
        content: t("warning_modal.large_data_detail", { amount : pagination.total }),
        onOk: (close) => {
          exportFunc(options)
          close()
        },
      })
    } else {
      exportFunc(options);
    }
  }

  const exportPDF = async ({ title = "", subtitle = null, landscape = false, fetchPageSize = 50 } = {}) => {
    const doc = new jsPDF(landscape ? 'l' : 'p', 'mm', [297, 210]);

    // Write header
    doc.setFont("THSarabunNew", "bold")
    doc.setFontSize(24)
    doc.text(title, doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' })

    // Subtitle & Filters
    doc.setFont("THSarabunNew", "normal")
    doc.setFontSize(16)
    if (subtitle) {
      doc.text(
        subtitle,
        doc.internal.pageSize.getWidth() / 2,
        28,
        { align: 'center' }
      )
    }

    doc.text(
      searchItems.map(item => `${item.title} : ${item.value}`).join(", "),
      doc.internal.pageSize.getWidth() / 2,
      subtitle ? 36 : 28,
      { align: 'center' }
    )

    // Body
    const allData = await fetchReportData(1, fetchPageSize, [])
    const skipReportColumns = props.columns.filter(col => col.skipReport == null)
    const columns = skipReportColumns.map(col => col.title);
    const rows = allData.map(d => skipReportColumns.map(col =>
      col.renderReport ? col.renderReport(d[col.dataIndex]) :
        col.render ? col.render(d[col.dataIndex]) :
          d[col.dataIndex]
    ))

    const options = {
      margin: {
        top: subtitle ? 40 : 32
      },
      theme: "grid",
      styles: {
        font: "THSarabunNew",
        fontSize: 16
      },
      headStyles: {
        fillColor: [94, 187, 71],
        textColor: [255, 255, 255],
        valign: 'middle',
        halign: 'center'
      }
    }

    doc.autoTable({
      head: [columns],
      body: rows,
      ...options
    })

    // Set footer
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setFontSize(14);
      doc.setPage(i);
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text(
        'Page ' + String(i) + ' of ' + String(pageCount),
        landscape ? 260 : 173,
        pageHeight - 8,
      );
    }

    doc.output('dataurlnewwindow')
  }

  const exportXlsx = async ({ title = "", subtitle = null, filename = null, fetchPageSize = 50 } = {}) => {

    const allData = await fetchReportData(1, fetchPageSize, [])
    const skipReportColumns = props.columns.filter(col => col.skipReport == null)
    const columns = skipReportColumns.map(col => col.title);
    const rows = allData.map(d => skipReportColumns.map(col =>
      col.renderReport ? col.renderReport(d[col.dataIndex]) :
        col.render ? col.render(d[col.dataIndex]) :
          d[col.dataIndex]
    ))

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(
      [
        [title],
        [subtitle ? subtitle : ""],
        [searchItems.map(item => `${item.title} : ${item.value}`).join(", ")],
        columns,
        ...rows,
      ],
    )

    // Merge header
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: columns.length } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: columns.length } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: columns.length } },
    ];
    worksheet["!merges"] = merge;

    // Export data
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    XLSX.writeFile(workbook, filename != null ? filename : `download.xlsx`, { compression: true });
  }

  React.useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  React.useEffect(() => {
    props.onData(data)
  }, [data])

  React.useEffect(() => {
    if (props.autoFetch) {  // Auto fetch data if set autoFetch or rely on ref to trigger fetch data
      fetchData(props.url, 1, props.pageSize)
    }
  }, [])

  return (
    <>
      {messageContextHolder}
      {modalContextHolder}
      <Table
        key={props.key}
        style={{ ...props.style }}
        loading={isLoading}
        size={props.size}
        bordered
        dataSource={data}
        columns={props.columns.map(col => ({
          ...col,
          ...(col[FLAG_COLUMN_SEARCH] && getColumnSearchProps(
            _.get(col, 'dataIndex'),
            _.get(col, 'title', _.get(col, 'dataIndex')),
            _.get(col, 'searcherOptions', null),
            _.get(col, 'dateSearcher', null),
            _.get(col, 'render', null)
          )),
        }))}
        pagination={props.hidePagination ? false : pagination}
        onChange={(pagination, filter, sorter) => {
          const sortDirection = sorter['order'] === 'descend' ? '-' : ''
          const sortField = sorter['field']
          const sortParam = sorter['column'] == null ? null : `${sortDirection}${sortField}`
          setFilterParams(filter)
          setSortParams(sortParam)
          fetchData(
            props.url,
            pagination['current'],
            pagination['pageSize'],
            sortParam,
            filter
          )
        }}
        onShowSizeChange={(current, size) => fetchData(URL, current, size)}
        onRow={props.onRow}
        locale={{ emptyText: renderEmptyTable }}
        rowClassName={props.rowClassName}
        footer={props.footer}
      />
    </>
  )
})

TSTable.defaultProps = {
  columns: [],
  url: null,  // URL to report e.g. /api/member/member/
  data: null, // For manual data
  autoFetch: true,  // set to true for fetch data on component mounted automatically or use ref to handle fetch action
  rowKey: "id",  // KEY props from data
  style: {},
  size: "middle",  // large, middle, small
  key: "", // for QueueAnim
  params: {}, // for send query params
  paginationOptions: {
    position: ["bottomCenter"],
    showTotal: total => `Total ${total} rows`,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"]
  },  // Pagination Options
  hidePagination: false, // Should we hide the pagination
  pageSize: 10,  // Default Page Size
  rowClassName: {},  // For setting bg color rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
  onRow: (record, rowIndex) => { },  // On row clicked
  onData: () => { } // On data fetch changed
}

TSTable.propTypes = {
  columns: propTypes.array.isRequired,
  url: propTypes.string.isRequired,
  autoFetch: propTypes.bool,
  rowKey: propTypes.string,
  style: propTypes.object,
  size: propTypes.string,
  key: propTypes.string,
  params: propTypes.object,
  paginationOptions: propTypes.object,
  hidePagination: propTypes.bool,
  pageSize: propTypes.number,
  onRow: propTypes.func,
  onData: propTypes.func,
}

export default TSTable;